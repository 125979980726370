import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";

import { Maybe, Query, SanityTeam } from "@graphql-types";
import { Container, H2, VerticalSeparator } from "@util/standard";
import { BlocksContent, Image } from "@sub";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";

const Wrapper = styled(Container)`
  flex-direction: column;
  margin-top: 90px;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 65px;
  }
`;

const ContentContainer = styled(Container)`
  width: 70%;
  margin: 160px auto;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 50px auto;
  }
`;

const ImageContainer = styled(Container)<{}>`
  width: 100%;
  margin: 0;
  position: relative;
  height: 600px;
  overflow: hidden;
  justify-content: flex-end;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 300px;
  }
`;

const AboutContainer = styled(Container)`
  width: 100%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const TextContainer = styled(Container)`
  flex-direction: column;
`;

const BlockContainer = styled(Container)<{ maxWidth?: number }>`
  max-width: ${(props) => props.maxWidth ?? 450}px;
  flex-direction: column;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: 100%;
  }
`;

const TeamMembers = styled(Container)`
  flex-direction: column;
  margin: 130px auto;
  width: 50%;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80%;
  }
`;

const About = () => {
  const { sanityAboutUsPage }: Query = useStaticQuery(graphql`
    {
      sanityAboutUsPage {
        aboutUsInfo {
          title
          description {
            _rawColumnContent(resolveReferences: { maxDepth: 4 })
          }
          image {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        image {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
          }
        }
        teamMembers {
          name
          bio {
            _rawColumnContent(resolveReferences: { maxDepth: 4 })
          }
          _key
        }
      }
    }
  `);

  if (sanityAboutUsPage == null) {
    return null;
  }

  const Member = (props: Maybe<SanityTeam>) => (
    <Container flexDirection="column">
      <Container>
        <VerticalSeparator />
        <H2 margin="0">{props?.name}</H2>
      </Container>
      <BlocksContent blocks={props?.bio?._rawColumnContent} />
    </Container>
  );

  return (
    <Wrapper>
      <ContentContainer>
        <AboutContainer>
          <TextContainer>
            <H2 margin="10px 0">{sanityAboutUsPage.aboutUsInfo?.title}</H2>
            <BlockContainer>
              <BlocksContent
                blocks={
                  sanityAboutUsPage.aboutUsInfo?.description?._rawColumnContent
                }
              />
            </BlockContainer>
          </TextContainer>

          <ImageContainer>
            <Image
              fluid={
                sanityAboutUsPage.aboutUsInfo?.image?.asset
                  ?.fluid as FluidObject
              }
              objectFit="contain"
            />
          </ImageContainer>
        </AboutContainer>
      </ContentContainer>
      <ImageContainer>
        <Image
          fluid={sanityAboutUsPage.image?.asset?.fluid as FluidObject}
          isBackground
          backgroundStyle={{
            width: "90%",
          }}
        />
      </ImageContainer>
      <TeamMembers>
        <H2 margin="0 0 50px 0">CRA Officers and Staff</H2>
        <Container flexDirection="column">
          {sanityAboutUsPage.teamMembers?.map((teamMember) => {
            return <Member key={teamMember?._key} {...teamMember} />;
          })}
        </Container>
      </TeamMembers>
    </Wrapper>
  );
};

export default About;
