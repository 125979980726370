import React from "react";
import { graphql, PageProps } from "gatsby";

import ContactUsBlock from "@components/contact/contactUsBlock";
import SEO from "@components/shared/seo";
import { Query } from "@graphql-types";
import AboutUsHero from "@components/aboutUs/aboutUsHero";
import About from "@components/aboutUs/about";

interface Props extends PageProps {
  data: Query;
}

export default function AboutUs({ data }: Props) {
  const { sanityAboutUsPage } = data;

  return (
    <div>
      <SEO seoData={sanityAboutUsPage?.seo} />
      <AboutUsHero />
      <About />
      <ContactUsBlock />
    </div>
  );
}

export const query = graphql`
  query AboutUsQuery {
    sanityAboutUsPage {
      seo {
        pageDescription
        pageKeyWords
        pageTitle
        slug {
          current
        }
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
